@import '../theme.scss';

#formBrokerPasswordReset {
  background-color: $color-light-grey;
  padding: 0 $standard-padding-x;

  .mainContent {
    > .inner {
      max-width: 1350px;
      min-height: 500px;
      background-color: $color-white;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      padding: 50px $standard-padding-x;
      align-content: space-between;

      .content {
        max-width: 750px;
        text-align: center;
        width: 100%;

        .title {
          color: $color-blue;
          font-size: clamp(22px, 3vw, 24px);
          font-weight: 600;
          width: auto;
          display: inline-block;

          &::after {
            width: 20%;
            display: block;
            content: '';
            background-color: $color-red-light;
            height: 2px;
            margin-top: 10px;
            border-bottom: unset;
            padding-top: 0;
          }
        }

        .intro {
          margin-top: 20px;
          font-size: 14px;
          font-weight: 500;
        }

        .form {
          max-width: 390px;
          margin-top: 20px;
          text-align: left;
          grid-gap: 20px;
          width: 100%;
        }

        .buttonContainer {
          margin-top: 50px;

          .buttons {
            button {
              width: 100%;
              min-width: 190px;
              height: 60px;
              max-width: 390px;
            }
          }
        }

        .info {
          max-width: 390px;
          text-align: left;
          margin-top: 30px;

          p {
            color: $color-dark-grey;
            font-size: 11px;
            font-weight: 600;
          }

          ul {
            list-style: disc;
            padding-top: 5px;
            padding-left: 1em;

            li {
              color: $color-dark-grey;
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $screenBreak-mobile) {
  #formBrokerPasswordReset {
    padding-left: 0;
    padding-right: 0;

    .mainContent {
      > .inner {
        .content {
          .buttonContainer {
            .buttons {
              grid-template-columns: 1fr;
              grid-template-areas: 'next' 'previous';

              button {
                width: 100%;
              }

              &.white {
                grid-area: previous;
              }

              &.red {
                grid-area: next;
              }
            }
          }
        }
      }
    }
  }
}
